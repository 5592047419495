/* eslint-disable react/destructuring-assignment */
import React from 'react';
import classnames from 'classnames';
import {
  Header,
  HeroImage,
  LayoutUI,
  LeadText,
  Stack,
  TextStyle,
  WithFloatingArrow
} from '../../ui-kit';
import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';
import ROUTES from '../../utils/routes';

import HeaderImage from '../../assets/images/science-of-happiness/header.jpg';
import './ScienceOfHappiness.scss';

const DAYS = [
  {
    body: [
      'Mindfulness, the ancient practice of focusing non-judgmental awareness on the present moment and thoughts, is increasingly recognized in today’s scientific community as an effective way to reduce stress, increase self-awareness, enhance emotional intelligence, and effectively manage painful thoughts and feelings.',
      'The mind is highly trainable through various mindfulness practices like meditation, Mindfulness-Based Stress Reduction (MBSR), and Mindfulness-Based Cognitive Therapy (MBCT).',
      'Anyone can stand to benefit from cultivating the skills of mindfulness – particularly in our busy modern lifestyles that are often characterized by stress, sleep deprivation, multitasking, and digital distractions. Shift your focus, and create a new mindset.'
    ],
    color: 'red',
    handle: 'mindful-monday',
    name: 'Mindful Monday',
    subtitle: 'Mindfulness, Focus, Mindset'
  },
  {
    body: [
      'Research has found that gratitude can significantly increase your happiness, and protect you from stress, negativity, anxiety, and depression.',
      'Developing a regular gratitude practice is one of the easiest ways to counter the brain’s negativity bias, or the tendency to cling to the negative things in our environment. By intentionally focusing on the good parts of your day, the positivity grows. In fact, it only takes 21 days of writing down three things you are grateful for every day to begin reaping the benefits.',
      'Moral of the story? Count your blessings daily; it has a measurable, positive effect on your well-being'
    ],
    color: 'orange',
    handle: 'grati-tuesday',
    name: 'Grati-Tuesday',
    subtitle: 'Gratitude, Appreciation, Love'
  },
  {
    body: [
      'Happiness is good for your health, and vice versa. A review of hundreds of studies shows compelling evidence that happier people have better overall health, and live longer than their less happy peers. Anxiety, depression, pessimism, and a lack of enjoyment in daily activities have all been found to be associated with higher rates of disease and shorter lifespans.',
      'According to research, if you have a good sense of well-being, it’s easier to maintain good habits such as exercising, eating a balanced diet, and getting enough sleep. People who have an optimistic mindset may be more likely to engage in healthy behaviors because they perceive them as helpful in achieving their goals.',
      'Taking care of your physical wellness may well be the most effective instant happiness booster of all.'
    ],
    color: 'yellow',
    handle: 'wellness-wednesday',
    name: 'Wellness Wednesday',
    subtitle: 'Body Wellness, Motivation, Self-Compassion'
  },
  {
    body: [
      'Happiness and altruism are intimately linked – doing good is an essential ingredient to being happy, and happiness helps spark kindness and generosity.',
      'Science suggests that how we spend our time and resources is as important, if not more important, than the amount of money we make. Giving to others releases endorphins, activating the parts of our brains that are associated with trust, pleasure, and social connection. Being altruistic and spending money on others leads to higher levels of happiness than spending it on oneself.',
      'Happiness, in turn, increases the chance that we’ll be altruistic in the future, creating a positive feedback loop of generosity and happiness. As the researchers write, “Policies that promote well-being may help to generate a virtuous circle, whereby increases in well-being promote altruism that, in turn, increases well-being. Such a cycle holds the promise of creating a ‘sustainable happiness’ with broad benefits for altruists, their beneficiaries, and society at large.”'
    ],
    color: 'teal',
    handle: 'thoughtful-thursday',
    name: 'Thoughtful Thursday',
    subtitle: 'Compassion, Giving Back, Altruism'
  },
  {
    body: [
      'When Dr. Brené Brown conducted thousands of interviews to discover what lies at the root of social connection, a thorough analysis of the data revealed what it was: vulnerability.',
      'To be clear, vulnerability does not mean being weak or submissive. To the contrary, it implies the courage to be your authentic self. The rewards of vulnerability are immeasurable. When you embrace an authentic and vulnerable stance to life, people will meet you there in that openness, allowing you to experience true connection.',
      'Forgiveness is a byproduct of living authentically and vulnerably. Forgiveness doesn’t mean tolerance of error but rather a patient encouragement of growth. Practicing forgiveness doesn’t only benefit the person you forgive; research shows that it has tangible benefits for yourself as well. So the next time you’re holding a grudge, try letting it go – for your own happiness!'
    ],
    color: 'blue',
    handle: 'freedom-friday',
    name: 'Freedom Friday',
    subtitle: 'Authenticity, Vulnerability, Forgiveness, Letting Go'
  },
  {
    body: [
      'Our busy lives often leave us stretched for time to connect with others, but science suggests that social connection should be at the top of our to-do lists.',
      'Dr. Emma Seppala from Stanford’s Center for Compassion and Altruism Research and Education (CCARE) says that when connection with others is present, it can boost mental and physical health, and even increase immunity and longevity.',
      'Relatedly, happiness is collective. Our happiness depends on the happiness of those we are connected to. Science shows that through practicing happiness, we make those we come into contact with happier. In other words, happiness is contagious –to the 3rd degree of contact (a friend of a friend of a friend)!'
    ],
    color: 'violet',
    handle: 'social-saturday',
    name: 'Social Saturday',
    subtitle: 'Social Connection, Nature Connection, Empathy'
  },
  {
    body: [
      'Many people tell themselves, “If I work hard, I’ll be successful. If I’m successful, I’ll be happy.” But recent discoveries in psychology and neuroscience show that this formula is backward: happiness fuels success, not the other way around. In fact, science has shown that, “The brain at positive is 31% more productive than at negative, neutral or stressed.” – Shawn Achor, Harvard',
      'Researchees have found that the type of work you do is key: engaging in meaningful activity is a big indicator of happiness. As Harvard happiness expert Tal Ben-Shahar says, “Happiness lies at the intersection of pleasure and meaning.”',
      'In addition to seeking work imbued with a sense of purpose, scientists have discovered that people thrive in environments where their strengths are emphasized. If we are actively involved in trying to reach a goal, or an activity that is challenging but well suited to our skills, we experience a joyful state or what famous psychologist Dr. Mihaly Csikszentmihalyi calls “flow.”'
    ],
    color: 'purple',
    handle: 'soul-sunday',
    name: 'Soul Sunday',
    subtitle: 'Meaning, Purpose, Strengths, Soul'
  }
];

export default class ScienceOfHappiness extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleDayClick = this.handleDayClick.bind(this);

    this.state = {
      selectedDay: 0
    };
  }

  handleDayClick(index) {
    this.setState({ selectedDay: index });
  }

  render() {
    const selectedDayContent = DAYS[this.state.selectedDay];

    return (
      <Layout>
        <Navbar />
        <MobileNavbar />
        <div className="scienceOfHappiness">
          <HeroImage height="large" image={HeaderImage} />

          <LayoutUI narrow>
            <TextStyle center>
              <Stack alignment="center" vertical>
                <Header>Science of Happiness</Header>
                <LeadText>
                  Scientific research has turned its focus on how happiness can
                  be sustained and even increased. Research has confirmed that
                  through intentional practices, we can actually change the
                  neural pathways of our brain to become happier. In fact, only
                  10% of our happiness is due to our external circumstances and
                  a full 90% is based on our inner environment!
                </LeadText>
              </Stack>
            </TextStyle>
          </LayoutUI>

          <LayoutUI narrow>
            <div className="DayCirclesContainer">
              {DAYS.map(({ name, color }, index) => {
                const dayCirlceColor = classnames(`DayCircle-${color}`);

                // const xyz = dayCirlceColor);

                const circleClassName = classnames(
                  index === this.state.selectedDay
                    ? 'DayCircle-Active'
                    : 'DayCircle',
                  dayCirlceColor
                );

                return (
                  <button
                    className="DayLink"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClick={() => this.handleDayClick(index)}
                    type="button"
                  >
                    <div className={circleClassName}>{name}</div>
                    <div className="DayCircleCaret" />
                  </button>
                );
              })}
            </div>

            <div className={`DayContentContainer-${selectedDayContent.color}`}>
              <TextStyle color="black">
                <Stack alignment="center" vertical spacing="extraLoose">
                  <div className="DayContentHeader">
                    <Header>{selectedDayContent.name}</Header>
                    <p>{selectedDayContent.subtitle}</p>
                  </div>

                  <Stack vertical>
                    {selectedDayContent.body.map(paragraph => (
                      <p key={paragraph}>{paragraph}</p>
                    ))}
                  </Stack>

                  <Stack spacing="extraLoose">
                    <Stack.Item fill>
                      <WithFloatingArrow>
                        <a
                          href={ROUTES.scienceOfHappinessArticle(
                            selectedDayContent.handle,
                            'inspirational'
                          )}
                          className="DayContentLink"
                        >
                          Inspirational Articles
                        </a>
                      </WithFloatingArrow>
                    </Stack.Item>
                    <Stack.Item fill>
                      <WithFloatingArrow>
                        <a
                          href={ROUTES.scienceOfHappinessArticle(
                            selectedDayContent.handle,
                            'scientific'
                          )}
                          className="DayContentLink"
                        >
                          Scientific Articles
                        </a>
                      </WithFloatingArrow>
                    </Stack.Item>
                  </Stack>
                </Stack>
              </TextStyle>
            </div>
          </LayoutUI>
        </div>
      </Layout>
    );
  }
}
