/* eslint-disable react/prop-types */
import React from 'react';
import Transition from 'react-transition-group/Transition';

const childTransitionStyles = {
  entering: { opacity: 0, transform: 'translate3d(0, 40px, 0)' },
  entered: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  exiting: { opacity: 0, transform: 'translate3d(0, 0, 0)' },
  exited: { opacity: 0, transform: 'translate3d(0, 0, 0)' }
};

export default function MobileLinkList({ children, visible }) {
  return (
    <Transition in={visible} timeout={150} appear>
      {state => (
        <div>
          {React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
              style: {
                ...childTransitionStyles[state],
                transitionDelay: `${index * 75}ms`
              }
            })
          )}
        </div>
      )}
    </Transition>
  );
}
