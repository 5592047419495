/* eslint-disable react/prop-types */
import React from 'react';
import Transition from 'react-transition-group/Transition';

import * as styles from './mobile-dropdown.module.scss';

const dropdownTransitionStyles = {
  entering: { maxHeight: 200, marginBottom: -14 },
  entered: { maxHeight: 200, marginBottom: -14 },
  exiting: { maxHeight: 0 },
  exited: { maxHeight: 0 }
};

export default function MobileDropdown({ children, visible }) {
  return (
    <Transition in={visible} timeout={200} appear>
      {state => (
        <div
          className={styles.Container}
          style={{ ...dropdownTransitionStyles[state] }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
}
