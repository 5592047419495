/* eslint-disable camelcase */
export const IN_PAGE_ANCHORS = {
  featuredAmbassadors: 'featuredAmbassadors'
};

const ROUTES = {
  // Internal
  about: () => '/about',
  aboutProfile: name => `/about/${name}`,
  ambassadors: () => '/get-involved/ambassadors',
  ambassadorsFeatured: () =>
    `/get-involved/ambassadors#${IN_PAGE_ANCHORS.featuredAmbassadors}`,
  ambassadorsProfile: id => `/get-involved/ambassadors/${id}`,
  ambassadorsLearnMore: () => '/get-involved/ambassadors/learn-more',
  // these are disabled for now
  // ambassadorsApplication: () => '/get-involved/ambassadors/amabassador-application',
  // ambassadorsRegionalApplication: () => '/get-involved/ambassadors/regional-application',
  careers: () => '/careers',
  coaching: () => '/coaching',
  contact: () => '/contact',
  education: () => '/get-involved/education',
  educationProgram: programName => `/get-involved/education/${programName}`,
  preferredSchoolProgram: () =>
    '/get-involved/education/preferred-school-program',
  faq: () => '/faq',
  filmScreeningRequest: () =>
    '/get-involved/speaker-series/film-screening-request-form',
  home: () => '/',
  getInvolved: () => '/get-involved',
  inspirationalArticle: day => `/science-of-happiness/${day}/inspirational`,
  liveEvents: () => '/get-involved/live-events',
  newsletterSuccess: () => '/thank-you',
  onlineCourse: () => '/get-involved/online-course',
  onlineCourseComingSoon: () =>
    'https://shop.projecthappiness.org/collections/all-products/products/7-doors-to-happiness-online-course',
  ourStory: () => '/our-story',
  policy: () => '/policy',
  scienceOfHappiness: () => '/science-of-happiness',
  scienceOfHappinessArticle: (day, type) =>
    `/science-of-happiness/${day}/${type}`,
  scholarshipApplication: () =>
    '/get-involved/education/scholarship-application',
  speakerSeriesRequest: () =>
    '/get-involved/speaker-series/speaker-series-request-form',
  terms: () => '/terms',
  virtualRace: () => 'https://www.charityfootprints.com/eventdetails?id=215',

  // External
  ambassadorSignUp: () => 'http://eepurl.com/bUXWUr',
  blog: () => 'https://shop.projecthappiness.org/blogs/project-happiness',
  circles: () => 'https://circles.projecthappiness.org',
  donate: () => 'https://bitly.com/SupportProjectHappiness',
  facebook: () => 'https://www.facebook.com/projecthappiness/',
  homePageVideo: () => 'https://vimeo.com/288409515',
  instagram: () => 'https://www.instagram.com/projecthappiness_org/',
  newsletterPost: () =>
    'https://projecthappiness.us9.list-manage.com/subscribe/post?u=2e45f17d3d4a1ef72e24e6d3f&id=286e03f42f',
  sevenDoorsOrder: () => 'http://7doorstohappiness.info/7-doors-pre-sale/',
  shop: () => 'https://shop.projecthappiness.org/',
  shopBook: () =>
    'https://shop.projecthappiness.org/collections/all-products/products/project-happiness-handbook',
  shop2019Book: () => 'https://bitly.com/EmotionalAdvantageBook',
  shopDVD: () =>
    'https://shop.projecthappiness.org/collections/all-products/products/project-happiness-dvd',
  twitter: () => 'https://twitter.com/projecthappy',
  quiz: () => 'https://projecthappiness.typeform.com/to/oATXmu',
  youtube: () => 'https://www.youtube.com/user/projecthappiness'
};

export const SHOP_ROUTES = {
  shop: () => 'https://shop.projecthappiness.org/',
  blog: () => 'https://shop.projecthappiness.org/blogs/project-happiness',
  starterPacks: () =>
    'https://shop.projecthappiness.org/collections/all-products/products/k-5-starter-pack',
  elementary: () => 'https://shop.projecthappiness.org/collections/elementary',
  middleSchool: () =>
    'https://shop.projecthappiness.org/collections/middle-school',
  highSchool: () =>
    'https://shop.projecthappiness.org/collections/all-products/products/high-school-curriculum',
  youngAdult: () => 'https://shop.projecthappiness.org/collections/young-adult',
  elementaryBronze: () =>
    'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45234107982',
  elementarySilver: () =>
    'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45333631886',
  elementaryPlatinum: () =>
    'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45333631950',
  middleSchoolBronze: () =>
    'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45333632014',
  middleSchoolSilver: () =>
    'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45333632078',
  middleSchoolPlatinum: () =>
    'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45333632142'
};

export const GOOGLE_CLOUD_ROUTES = {
  base: () => 'https://proj-happiness.storage.googleapis.com/',
  homePageBackgroundVideo: () =>
    'https://storage.googleapis.com/proj-happiness/assets/home-cover-video-480.mov',
  ambassadorMainImage: ambassador_id =>
    `https://proj-happiness.storage.googleapis.com/ambassadors/images/${ambassador_id}`,
  ambassadorFeaturedImage1: ambassador_id =>
    `https://proj-happiness.storage.googleapis.com/ambassadors/featured_image_1s/${ambassador_id}`,
  ambassadorFeaturedImage2: ambassador_id =>
    `https://proj-happiness.storage.googleapis.com/ambassadors/featured_image_2s/${ambassador_id}`,
  newsletterSuccessDownload: () =>
    'https://storage.googleapis.com/proj-happiness/assets/MayYouBeInspired.pdf'
};

// This value is brought in from the local .env
// That way we can point to e.g. localhost:3000 in development and
// the actual deployed URL in production.
export const API_BASE =
  process.env.NODE_ENV === 'production'
    ? 'https://proj-happiness-embedded-app.herokuapp.com'
    : 'http://localhost:3000';
export const API_ROUTES = {
  getAmbassadors: () => ({
    path: `${API_BASE}/api/ambassadors`,
    method: 'GET'
  }),
  getAmbassador: id => ({
    path: `${API_BASE}/api/ambassadors/${id}`,
    method: 'GET'
  }),
  ambassadorApplicationCreate: () => ({
    path: `${API_BASE}/api/ambassador_applications`,
    method: 'POST'
  }),
  filmScreeningRequestCreate: () => ({
    path: `${API_BASE}/api/film_screening_requests`,
    method: 'POST'
  }),
  scholarshipApplicationCreate: () => ({
    path: `${API_BASE}/api/scholarship_applications`,
    method: 'POST'
  }),
  speakerSeriesRequestCreate: () => ({
    path: `${API_BASE}/api/speaker_series_requests`,
    method: 'POST'
  })
};

export default ROUTES;
