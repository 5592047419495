import React, { useState } from 'react';
import Transition from 'react-transition-group/Transition';
import { Link } from 'gatsby';
import { LayoutUI, Stack, TextStyle } from '../../ui-kit';
import HamburgerIcon from './components/hamburger-icon/hamburger-icon';
import MobileLinkList from './components/mobile-link-list/mobile-link-list';
import MobileDropdown from './components/mobile-dropdown/mobile-dropdown';

import * as styles from './mobile-navbar.module.scss';

const menuTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const closeButtonTransitionStyles = {
  entering: { opacity: 0, transform: 'translate3d(0, 100px, 0)' },
  entered: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  exiting: { opacity: 0, transform: 'translate3d(0, 0, 0)' },
  exited: { opacity: 0, transform: 'translate3d(0, 0, 0)' }
};

const MobileNavbar = assignedColor => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shopDropdownOpen, setShopDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setDropdownOpen(false);
    setShopDropdownOpen(false);
    setMenuOpen(!menuOpen);
  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleShopDropdownClick = () => {
    setShopDropdownOpen(!shopDropdownOpen);
  };

  const handleLinkClick = () => {
    setDropdownOpen(false);
    setShopDropdownOpen(false);
    setMenuOpen(false);
  };

  const handleCloseButtonClick = () => {
    setDropdownOpen(false);
    setShopDropdownOpen(false);
    setMenuOpen(false);
  };

  const lightHeader = assignedColor.color === 'white' && !menuOpen;

  return (
    <div className={lightHeader ? styles.ContainerLight : styles.Container}>
      <nav
        className={
          menuOpen ? styles.TopBarContainerFixed : styles.TopBarContainer
        }
      >
        <LayoutUI>
          <Stack alignment="center">
            <Stack.Item fill>
              <Link to="/" onClick={handleLinkClick}>
                <h1 className={styles.Title}>Project Happiness Global</h1>
              </Link>
            </Stack.Item>
            <HamburgerIcon
              open={menuOpen}
              onClick={handleHamburgerClick}
              color={lightHeader ? 'white' : 'black'}
            />
          </Stack>
        </LayoutUI>
      </nav>

      <Transition in={menuOpen} timeout={150} mountOnEnter unmountOnExit>
        {state => (
          <div
            className={styles.MenuContainer}
            style={{ ...menuTransitionStyles[state] }}
          >
            <MobileLinkList visible={menuOpen}>
              <Link
                to="/our-story"
                className={styles.Link}
                onClick={handleLinkClick}
              >
                Our story
              </Link>
              <Link
                to="/science-of-happiness"
                className={styles.Link}
                onClick={handleLinkClick}
              >
                Science of happiness
              </Link>
              <span className={styles.Link}>
                <Link to="/get-involved" onClick={handleLinkClick}>
                  Get involved
                </Link>
                <button
                  type="button"
                  className={styles.GetInvolvedButton}
                  onClick={handleDropdownClick}
                >
                  <TextStyle color="yellow-dark">
                    {dropdownOpen ? '–' : '+'}
                  </TextStyle>
                </button>
              </span>
              <MobileDropdown visible={dropdownOpen}>
                <Link
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  to="/get-involved/education"
                >
                  Education
                </Link>
                <Link
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  to="/get-involved/online-course"
                >
                  Online course
                </Link>
                <a
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  href="https://circles.projecthappiness.org"
                >
                  Circles
                </a>
                <Link
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  to="/get-involved/ambassadors"
                >
                  Ambassadors
                </Link>
                <Link
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  to="/get-involved/live-events"
                >
                  Live events
                </Link>
              </MobileDropdown>
              <span className={styles.Link}>
                <a
                  href="https://shop.projecthappiness.org/"
                  onClick={handleLinkClick}
                >
                  Shop
                </a>
                <button
                  type="button"
                  className={styles.GetInvolvedButton}
                  onClick={handleShopDropdownClick}
                >
                  <TextStyle color="yellow-dark">
                    {dropdownOpen ? '–' : '+'}
                  </TextStyle>
                </button>
              </span>
              <MobileDropdown visible={shopDropdownOpen}>
                <a
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  href="https://shop.projecthappiness.org/collections/educators-and-parents"
                >
                  Education
                </a>
                <a
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  href="https://shop.projecthappiness.org/collections/parents"
                >
                  Parents and Family
                </a>
                <a
                  onClick={handleLinkClick}
                  className={styles.DropdownLink}
                  href="https://shop.projecthappiness.org/collections/all-products"
                >
                  All Products
                </a>
              </MobileDropdown>
              <a
                href="https://shop.projecthappiness.org/blogs/project-happiness"
                className={styles.Link}
                onClick={handleLinkClick}
              >
                Latest
              </a>
              <span className={styles.Link}>
                <a
                  href="https://bitly.com/SupportProjectHappiness"
                  className={(styles.Link, styles.LastLink)}
                  onClick={handleLinkClick}
                >
                  Donate
                </a>
              </span>
            </MobileLinkList>
          </div>
        )}
      </Transition>

      <Transition in={menuOpen} timeout={150} unmountOnExit mountOnEnter>
        {state => (
          <button
            type="button"
            className={styles.CloseButton}
            style={{
              ...closeButtonTransitionStyles[state]
            }}
            onClick={handleCloseButtonClick}
          >
            &times;
          </button>
        )}
      </Transition>
    </div>
  );
};

export default MobileNavbar;
