import React, { useState } from 'react';
import { Link } from 'gatsby';
import { LayoutUI, Stack, TextStyle } from '../../ui-kit';
import './navbar.scss';

const Navbar = assignedColor => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shopDropdownOpen, setShopDropdownOpen] = useState(false);

  const color = assignedColor.color || 'black';

  const dropdownMarkup = dropdownOpen ? (
    <div className="Dropdown">
      <Link to="/get-involved/education" className="DropdownLink">
        Education
      </Link>
      <Link to="/get-involved/online-course" className="DropdownLink">
        Online Course
      </Link>
      <a href="https://circles.projecthappiness.org" className="DropdownLink">
        Circles
      </a>
      <Link to="/get-involved/ambassadors" className="DropdownLink">
        Ambassadors
      </Link>
      <Link to="/get-involved/live-events" className="DropdownLink">
        Live events
      </Link>
    </div>
  ) : null;

  const shopDropdown = shopDropdownOpen ? (
    <div className="Dropdown">
      <a
        href="https://shop.projecthappiness.org/collections/educators-and-parents"
        className="DropdownLink"
      >
        Education
      </a>
      <a
        href="https://shop.projecthappiness.org/collections/parents"
        className="DropdownLink"
      >
        Parents and Families
      </a>
      <a
        href="https://shop.projecthappiness.org/collections/all-products"
        className="DropdownLink"
      >
        All Products
      </a>
    </div>
  ) : null;

  return (
    <nav className="Navbar Container">
      <LayoutUI>
        <div className={`Color--${color}`}>
          <Stack alignment="baseline">
            <Stack.Item fill>
              <Link to="/">
                <h1 className="Brand">Project Happiness Global</h1>
              </Link>
            </Stack.Item>

            <Stack.Item>
              <Stack spacing="loose">
                <Link to="/our-story" className="Link">
                  Our Story
                </Link>
                <Link to="/science-of-happiness" className="Link">
                  Science of Happiness
                </Link>
                <div
                  className="DropdownActivator"
                  onMouseEnter={() => setDropdownOpen(!dropdownOpen)}
                  onMouseLeave={() => setDropdownOpen(!dropdownOpen)}
                >
                  <Link to="/get-involved" className="Link">
                    Get Involved
                    <TextStyle color="yellow">+</TextStyle>
                  </Link>
                  {dropdownMarkup}
                </div>

                <div
                  className="DropdownActivator"
                  onMouseEnter={() => setShopDropdownOpen(!shopDropdownOpen)}
                  onMouseLeave={() => setShopDropdownOpen(!shopDropdownOpen)}
                >
                  <a href="https://shop.projecthappiness.org/" className="Link">
                    Shop
                    <TextStyle color="yellow">+</TextStyle>
                  </a>
                  {shopDropdown}
                </div>
                <a
                  href="https://shop.projecthappiness.org/blogs/project-happiness"
                  className="Link"
                >
                  Latest
                </a>
                <a
                  href="https://bitly.com/SupportProjectHappiness"
                  className="DonationLink"
                >
                  Donate •
                </a>
              </Stack>
            </Stack.Item>
          </Stack>
        </div>
      </LayoutUI>
    </nav>
  );
};

export default Navbar;
